import { useQuery } from 'react-query';
import { axios } from 'lib/axios';
import { deliveryStatusEndpoint, ordersQueryKeys } from '../constants';
import {
    Coordinate,
  DeliveryStatus,
  DeliveryStatusResponse,
  OrderState,
  TransportMethod,
} from 'typings/orders';
import { mockResponse } from '../test/mocks/mockData';

export function convertToDeliveryStatus(data: DeliveryStatusResponse): DeliveryStatus {
    let driver = '';
  try {
    driver = (data.driver_name ? data.driver_name.split(' ')[0] : '') as string;
  } catch (err) {
    console.log(err);
  }
  return {
    id: data.id,
    createdAt: data.created_at,
    destination: JSON.parse(data.destination),
    givenEta: data.given_eta,
    loadedAt: data.loaded_at || undefined,
    merchantName: data.merchant_name,
    origin: JSON.parse(data.origin),
    state: data.state as OrderState,
    transportMethod: data.transport_method as TransportMethod,
    courierLocations: data.courier_locations.map(([lng,lat]) => [Number.parseFloat(lng), Number.parseFloat(lat)]) as Coordinate[],
    merchantLocation: data.merchant_location as Coordinate,    
    customerLocation: data.customer_location as Coordinate,
    driverName: driver,
    driverPhone: data.driver_phone as string,
  };
}

async function fetchOrderByPhone(phone: string): Promise<DeliveryStatus> {
  const endpoint = deliveryStatusEndpoint + phone;
  const response: DeliveryStatusResponse = await axios.get(endpoint);
  const deliveryStatus = convertToDeliveryStatus(response);

  return deliveryStatus;
}

const mockResponses = [
  mockResponse.unassigned,
  mockResponse.pastFiveminutes,
  mockResponse.goingToCustomer,
  mockResponse.atCustomer,
];

async function fetchOrderByPhoneMock(phone: string): Promise<DeliveryStatus> {
  const response: DeliveryStatusResponse = await new Promise((resolve) => {
    const mockResponse = mockResponses.shift();
    resolve(mockResponse as DeliveryStatusResponse);
  });

  const deliveryStatus = convertToDeliveryStatus(response);

  return deliveryStatus;
}

const { order } = ordersQueryKeys;

export function useOrderByPhone(phone: string) {
  const query = useQuery({
    queryKey: [order, phone],
    queryFn: () => fetchOrderByPhone(phone),
    refetchInterval: 1000 * 3,
    useErrorBoundary: false, // here for now since the error state UI hasnt been defined
  });

  return query;
}
