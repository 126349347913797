import { OrderRoutes } from 'features/orders';

import { Redirect, Route, Switch } from 'react-router-dom';

const baseAppRoutes = {
  orders: '/orders',
  idVerification: '/id-verification',
};

export const AppRoutes = () => {
  const { orders } = baseAppRoutes;

  return (
    <Switch>
      <Route path={orders} component={OrderRoutes} />
      <Route path="**">
        <Redirect to={orders} />
      </Route>
    </Switch>
  );
};
