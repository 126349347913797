import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { Coordinate } from 'typings/orders';
import { GeoJSONSource } from 'mapbox-gl';
import {
  Bot as BotIcon,
  Customer as CustomerIcon,
  Merchant as MerchantIcon,
  Driver as DriverIcon,
} from 'assets/images/map';

export function newGeoJSONLineStringFeature(
  coordinates: Coordinate[]
): Feature<Geometry, GeoJsonProperties> {
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: coordinates,
    },
  };
}

export function newGeoJSONPointFeature(
  coordinate: Coordinate,
  title?: string
): Feature<Geometry, GeoJsonProperties> {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: coordinate,
    },
    properties: { title },
  };
}

export enum Icon {
  DRIVER = 'driver',
  BOT = 'bot',
  MERCHANT = 'merchant',
  CUSTOMER = 'customer',
}

export const ICONS = {
  [Icon.DRIVER]: DriverIcon,
  [Icon.BOT]: BotIcon,
  [Icon.MERCHANT]: MerchantIcon,
  [Icon.CUSTOMER]: CustomerIcon,
};

export function renderIcon(
  map: mapboxgl.Map,
  icon: Icon,
  id: string,
  data: Feature<Geometry, GeoJsonProperties>
) {
  if (!map.hasImage(icon)) {
    map.loadImage(ICONS[icon], (err, img) => {
      if (img && !map.hasImage(icon)) {
        map.addImage(icon, img);
      }
    });
  }

  const source = map.getSource(id) as GeoJSONSource;
  if (!source) {
    map.addSource(id, { type: 'geojson', data });
  } else {
    source.setData(data);
  }
  if (!map.getLayer(id)) {
    map.addLayer({
      id,
      type: 'symbol',
      source: id,
      layout: {
        'icon-image': icon,
        'icon-size': 0.03,
        'text-field': ['get', 'title'],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-offset': [0, 2],
        'text-anchor': 'top',
      },
      paint: {
        'text-color': 'white',
      },
    });
  }
}

export function renderRoute(
  map: mapboxgl.Map,
  id: string,
  data: Feature<Geometry, GeoJsonProperties>
) {
  const source = map.getSource(id) as GeoJSONSource;
  if (!(source && source.setData(data))) {
    map.addSource(id, { type: 'geojson', data });
  }
  if (!map.getLayer(id)) {
    map.addLayer({
      id,
      type: 'line',
      source: id,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'white',
        'line-width': 8,
      },
    });
  }
}

export function cleanResourcesById(id: string, map?: mapboxgl.Map) {
  if (map) {
    if (map.getLayer(id)) {
      map.removeLayer(id);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  }
}
