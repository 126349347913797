import { useParams } from 'react-router';
import { DeliveryStatus as DeliveryStatusType } from 'typings/orders';
import { useOrderByPhone } from '..';
import { DeliveryDrawer } from '../components/DeliveryDrawer/DeliveryDrawer';

export const DeliveryStatus = () => {
  const { phone } = useParams() as unknown as { phone: string };
  const { isError, isLoading, data } = useOrderByPhone(phone);

  if (isError) {
    return (
      <div className="h-screen flex items-center justify-center px-10 text-center text-coco-black text-semibold">
        Something went wrong getting your order.
      </div>
    );
  }

  if (isLoading) {
    return <div className="h-screen flex items-center justify-center">Loading...</div>;
  }

  const {
    createdAt,
    loadedAt,
    destination,
    merchantName,
    state,
    transportMethod,
    givenEta,
    courierLocations,
    driverName,
    driverPhone,
  } = data as DeliveryStatusType;

  return (
    <DeliveryDrawer
      orderState={state}
      orderLoadedAt={loadedAt}
      orderCreatedAt={createdAt}
      customerAddress={destination}
      merchantName={merchantName.replace(' @ Colony', '')}
      transportMethod={transportMethod}
      givenEta={givenEta}
      courierLocations={courierLocations}
      driverName={driverName}
      driverPhone={driverPhone}
    />
  );
};
