export type Coordinate = [number, number];

export interface DeliveryStatusResponse {
  id: string;
  created_at: string;
  destination: string;
  given_eta: number;
  loaded_at: string;
  merchant_name: string;
  origin: string;
  state: string;
  transport_method: string;
  courier_locations: [string, string][];
  merchant_location: Coordinate;
  customer_location: Coordinate;
  driver_name: string;
  driver_phone: string;
}

export interface DeliveryStatus {
  id: string;
  createdAt: string;
  destination: Address;
  givenEta: number;
  loadedAt?: string;
  merchantName: string;
  origin: Address;
  state: OrderState;
  transportMethod: TransportMethod;
  courierLocations: Coordinate[];
  merchantLocation: Coordinate;
  customerLocation: Coordinate;
  driverName: string;
  driverPhone: string;
}

export interface Address {
  address1: string;
  address2: string;
  suite: string;
  location: Coordinate;
}

export enum TransportMethod {
  ROBOT = 'robot',
  MIKE = 'mike',
  COURIER = 'courier',
  INTERNAL_COURIER = 'internal_courier',
}

export enum OrderState {
  UNASSIGNED = 'unassigned',
  GOING_TO_MERCHANT = 'going_to_merchant',
  AT_MERCHANT = 'at_merchant',
  GOING_TO_CUSTOMER = 'going_to_customer',
  AT_CUSTOMER = 'at_customer',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}
