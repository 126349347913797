import { useBuildRoutes } from 'hooks/useBuildRoutes'
import { Redirect, Route, Switch } from 'react-router-dom'
import { DeliveryStatus } from './DeliveryStatus'
import { NoPhoneFound } from './NoPhoneFound'

const routes = {
  deliveryStatus: 'delivery-status',
  noPhoneFound: 'no-phone-found',
}

export const OrderRoutes = () => {
  const { deliveryStatus, noPhoneFound } = routes

  const deliveryStatusRoute = useBuildRoutes(deliveryStatus)
  const noPhoneFoundRoute = useBuildRoutes(noPhoneFound)

  return (
    <Switch>
      <Route path={`${deliveryStatusRoute}/:phone`} component={DeliveryStatus} />
      <Route path={noPhoneFoundRoute} component={NoPhoneFound} />

      <Route path="**">
        <Redirect to={noPhoneFoundRoute} />
      </Route>
    </Switch>
  )
}
