import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Coordinate } from 'typings/orders';
import { cleanResourcesById, Icon, newGeoJSONPointFeature, renderIcon } from './mapUtils';

// https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-753496839
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken =
  'pk.eyJ1IjoibWFwc2NvY28iLCJhIjoiY2tyMm1udXdqMW4xaTJ3bzc4NHNvMnhndyJ9.nYd4SvdJOxbbwiUHdR2ZOQ';

export interface MapProps {
  couriers: Coordinate[];
  merchant: Coordinate;
  customer: Coordinate;
}

export const Map = ({ couriers, merchant, customer }: MapProps) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map>();
  const [lng] = useState(-118.244);
  const [lat] = useState(34.052);
  const [zoom] = useState(9);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (mapContainerRef.current && !map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v10',
        center: [lng, lat],
        zoom,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapContainerRef]);

  useEffect(() => {
    map.current?.on('load', () => setMapLoaded(true));
    if (!map.current || !mapLoaded) {
      return;
    }

    if (merchant) {
      const data = newGeoJSONPointFeature(merchant);
      renderIcon(map.current!, Icon.MERCHANT, Icon.MERCHANT, data);
    } else {
      cleanResourcesById(Icon.MERCHANT, map.current);
    }
    // TODO(@christie): Clean resources
    couriers.forEach((c, index) => {
      const identifier = `${Icon.BOT}-${index}`;
      const data = newGeoJSONPointFeature(c);
      renderIcon(map.current!, Icon.BOT, identifier, data);
    });
    if (customer) {
      const data = newGeoJSONPointFeature(customer);
      renderIcon(map.current!, Icon.CUSTOMER, Icon.CUSTOMER, data);
    } else {
      cleanResourcesById(Icon.CUSTOMER, map.current);
    }

    const { innerWidth: width } = window;

    const lats = [customer[1], merchant[1]];
    const lngs = [customer[0], merchant[0]];
    couriers.forEach(([lng, lat]) => {
      lngs.push(lng);
      lats.push(lat);
    });
    map.current.fitBounds(
      [
        [Math.max(...lngs), Math.max(...lats)],
        [Math.min(...lngs), Math.min(...lats)],
      ],
      // (Brad): This breakpoint needs to match up with media query defined in tailwind.config.js
      // and sidebar break point in components/SideBar/index.tsx
      { padding: { bottom: 350, top: 25, left: 25, right: 25 } }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couriers, merchant, customer, map.current]);

  return (
    <div>
      <div ref={mapContainerRef} className="w-full h-148" />
    </div>
  );
};
