type HeaderProps = {
  title: string;
  subtitle: string;
};

export const Header = ({ title, subtitle }: HeaderProps) => {
  return (
    <div className="flex flex-col justify-center space-y-1">
      <span
        id="delivery-title"
        className="block font-bold text-2xl text-center text-transparent bg-clip-text text-coco-black"
        // bg-gradient-to-br from-red-500 to-pink-400
      >
        {title}
      </span>
      <span id="delivery-subtitle" className="text-coco-gray-mid font-semibold text-xs text-center">
        {subtitle}
      </span>
    </div>
  );
};
