import clsx from 'clsx';
import { Step as StepC } from './Step';
import { useEffect, useState } from 'react';

export type StepName = 'Step 1' | 'Step 2' | 'Step 3' | 'Step 5';

export enum STEP_STATUS {
  COMPLETE = 'complete',
  CURRENT = 'current',
  UPCOMING = 'upcoming',
}

export type Step = {
  id: string;
  name: StepName;
  status: STEP_STATUS;
};

const initialStepValues: Step[] = [
  { id: 'step-1', name: 'Step 1', status: STEP_STATUS.UPCOMING },
  { id: 'step-2', name: 'Step 2', status: STEP_STATUS.UPCOMING },
  { id: 'step-3', name: 'Step 3', status: STEP_STATUS.UPCOMING },
  //   { id: 'step-4', name: 'Step 4', status: STEP_STATUS.UPCOMING },
  { id: 'step-5', name: 'Step 5', status: STEP_STATUS.UPCOMING },
];

type StepToProgressPercentage = {
  [k in StepName]: string;
};

const stepToProgressPercentage: StepToProgressPercentage = {
  'Step 1': 'w-0',
  'Step 2': 'w-1/3',
  'Step 3': 'w-2/3',
  //   'Step 4': 'w-3/4',
  'Step 5': 'w-full',
};

type ProgressBarProps = {
  stepToActivate: StepName;
};

export const ProgressBar = ({ stepToActivate }: ProgressBarProps) => {
  const [steps, setSteps] = useState(() => initialStepValues);
  useEffect(() => {
    const stepIdx = steps.findIndex((step) => step.name === stepToActivate);
    const updatedSteps = steps.map((step, i) => {
      if (step.name === stepToActivate) {
        return { ...step, status: STEP_STATUS.CURRENT };
      }

      if (i < stepIdx) {
        return { ...step, status: STEP_STATUS.COMPLETE };
      }

      return { ...step, status: STEP_STATUS.UPCOMING };
    });
    setSteps(updatedSteps);
    // eslint-disable-next-line
  }, [stepToActivate]);

  return (
    <div aria-label="progress-bar" className="flex w-full">
      <ol
        id="progress-bar-list"
        className="flex justify-between items-center w-full h-5 bg-gray-200 pr-px rounded-full overflow-hidden relative"
      >
        {/** Current step with tracing gradient background */}
        <div
          className={`absolute flex justify-end items-center inset-0 h-5 pr-px rounded-full bg-gradient-to-tr from-red-400  to-pink-400 ${stepToProgressPercentage[stepToActivate]} `}
        >
          <div
            className={
              'w-4.1 h-4.1 flex items-center justify-center bg-white hover:border-none rounded-full'
            }
          >
            <span className="h-2.5 w-2.5 bg-transparent rounded-full" aria-hidden="true" />
            <span className="sr-only">step</span>
          </div>
        </div>

        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={clsx(stepIdx !== steps.length - 1 ? 'w-full' : '', 'relative')}
          >
            <StepC id={step.id} name={step.name} status={step.status} />
          </li>
        ))}
      </ol>
    </div>
  );
};
