import dayjs from 'dayjs';
import { OrderState, TransportMethod } from 'typings/orders';
import { StepName } from '../PogressBar';
import CocoBotArrivedGif from 'assets/gifs/order-arrived-bot.gif';
import DeliveryBagGif from 'assets/gifs/deliver-bag.gif';
import DeliveryBagArrivedGif from 'assets/gifs/order-arrived-bag.gif';

function isRobotDelivery(transportMethod: TransportMethod): boolean {
  return [TransportMethod.ROBOT, TransportMethod.MIKE].includes(transportMethod);
}

function getEtaTitle(loadedAt: string, givenEta: number): string {
  const baseEta = dayjs(loadedAt).add(givenEta, 'minutes');
  const etaPlusFiveMinutes = dayjs(baseEta).add(5, 'minutes');
  return `${baseEta.format('h:mm A')}-${etaPlusFiveMinutes.format('h:mm A')}`;
}

export type Content = {
  headerTitle: string;
  headerSubtitle: string;
  deliveryMethod: string[];
  gif: string;
  deliveryStatusMessage: string[];
  progressBarStep: StepName;
};
export type DrawerContentKeys =
  | 'unassigned'
  | 'pastFiveminutes'
  | 'goingToCustomer'
  | 'atCustomer'
  | 'completed'
  | 'cancelled';

type DrawerContent = {
  [k in DrawerContentKeys]: Content;
};

export function getDrawerContent(
  orderTransportMethod: TransportMethod,
  orderCreatedAt: string,
  orderLoadedAt: string | undefined,
  givenEta: number,
  merchantName: string,
  orderState: OrderState,
  driverName: string,
  driverPhone: string
): Content {
  const isRobot = isRobotDelivery(orderTransportMethod);

  const drawerContent: DrawerContent = {
    unassigned: {
      headerTitle: 'Good news!',
      headerSubtitle: '',
      deliveryMethod: [],
      gif: DeliveryBagGif,
      deliveryStatusMessage: ['Your order has been', `accepted by ${merchantName}`],
      progressBarStep: 'Step 1',
    },
    pastFiveminutes: {
      headerTitle: 'Hang tight...',
      headerSubtitle: `Order accepted at ${dayjs(orderCreatedAt).format('h:mm A')}`,
      deliveryMethod: [],
      gif: DeliveryBagGif,
      deliveryStatusMessage: [`${merchantName} is working`, 'on your order!'],
      progressBarStep: 'Step 2',
    },
    goingToCustomer: {
      headerTitle: '',
      headerSubtitle: 'Estimated arrival time',
      deliveryMethod: [],
      gif: DeliveryBagGif,
      deliveryStatusMessage:
        isRobot || !driverName
          ? ['Your order is on the way!']
          : [`${driverName} is on the way`, 'with your order!'],
      progressBarStep: 'Step 3',
    },
    atCustomer: {
      headerTitle: isRobot
        ? 'Coco has arrived with your order!'
        : driverName
        ? `${driverName} has arrived with your order!`
        : 'Your order has arrived!',
      headerSubtitle: '',
      deliveryMethod: [],
      gif: isRobot ? CocoBotArrivedGif : DeliveryBagArrivedGif,
      deliveryStatusMessage: isRobot
        ? ['To open your Coco, follow the', 'instructions you’ve received', 'via text message']
        : [],
      progressBarStep: 'Step 5',
    },
    completed: {
      headerTitle: '',
      headerSubtitle: '',
      deliveryMethod: [],
      gif: isRobot ? CocoBotArrivedGif : DeliveryBagArrivedGif,
      deliveryStatusMessage: [`Your order from ${merchantName}`, 'has been delivered!'],
      progressBarStep: 'Step 5',
    },
    cancelled: {
      headerTitle: '',
      headerSubtitle: '',
      deliveryMethod: [],
      gif: DeliveryBagGif,
      deliveryStatusMessage: [`Your order from ${merchantName}`, 'has been canceled'],
      progressBarStep: 'Step 5',
    },
  };
  const orderTime = dayjs(orderCreatedAt);
  const diff = dayjs().diff(orderTime, 'minutes');
  switch (orderState) {
    case OrderState.UNASSIGNED:
      return diff > 5 ? drawerContent.pastFiveminutes : drawerContent.unassigned;
    case OrderState.GOING_TO_MERCHANT:
    case OrderState.AT_MERCHANT:
    default:
      return drawerContent.pastFiveminutes;
    case OrderState.GOING_TO_CUSTOMER:
      return orderLoadedAt
        ? {
            ...drawerContent.goingToCustomer,
            headerTitle: getEtaTitle(orderLoadedAt, givenEta),
          }
        : {
            ...drawerContent.goingToCustomer,
            headerTitle: 'Hang tight...',
            headerSubtitle: `Order accepted at ${dayjs(orderCreatedAt).format('h:mm A')}`,
          };
    case OrderState.AT_CUSTOMER:
      return drawerContent.atCustomer;
    case OrderState.COMPLETED:
      return drawerContent.completed;
    case OrderState.CANCELLED:
      return drawerContent.cancelled;
  }
}
