import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProgressBar, StepName } from '../PogressBar';
import { Header } from './Header';
import CocoLogo from 'assets/svg/coco-header-logo.svg';
import CocoBotSpinningGif from 'assets/gifs/coco-spinning.gif';
import CocoBotLoadedGif from 'assets/gifs/loaded-bot.gif';
import CocoBotArrivedGif from 'assets/gifs/order-arrived-bot.gif';
import DeliveryBagGif from 'assets/gifs/deliver-bag.gif';
import DeliveryBagArrivedGif from 'assets/gifs/order-arrived-bag.gif';
import { DeliveryStatusMessage } from './DeliveryStatusMessage';
import { OrderState, Address, TransportMethod, Coordinate } from 'typings/orders';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { NeedHelp } from 'features/orders';

dayjs.extend(duration);

type Content = {
  headerTitle: string;
  headerSubtitle: string;
  deliveryMethod: string[];
  gif: string;
  deliveryStatusMessage: string[];
  progressBarStep: StepName;
};

export type BottomDrawerProps = {
  orderCreatedAt: string;
  customerAddress: Address;
  orderState: OrderState;
  merchantName: string;
  transportMethod: TransportMethod;
  givenEta: number;
  courierLocations: Coordinate[];
  driverName: string;
};

export type DrawerContentKeys =
  | 'unassigned'
  | 'pastFiveminutes'
  | 'goingToCustomer'
  | 'atCustomer'
  | 'completed'
  | 'cancelled';

type DrawerContent = {
  [k in DrawerContentKeys]: Content;
};

export const isRobotDelivery = (transportMethod: TransportMethod): boolean => {
  return [TransportMethod.ROBOT, TransportMethod.MIKE].includes(transportMethod);
};

export function getEtaTitle(createdAt: string, givenEta: number): string {
  const orderTime = dayjs(createdAt);
  const baseEta = dayjs(orderTime).add(givenEta, 'minutes');
  const etaPlusFiveMinutes = dayjs(baseEta).add(5, 'minutes');

  return `${baseEta.format('h:mm A')}-${etaPlusFiveMinutes.format('h:mm A')}`;
}

function resolveDeliveryMethod(isRobot: boolean): string[] {
  return isRobot
    ? ['Delivering with Coco']
    : ['To ensure a speedy delivery', 'we’ve dispatched a human'];
}
export type ViewContent = 'order' | 'faq';

export const BottomDrawer = ({
  customerAddress,
  orderCreatedAt,
  orderState,
  merchantName,
  transportMethod,
  courierLocations,
  givenEta,
  driverName,
}: BottomDrawerProps) => {
  const [open, setOpen] = useState(false);

  const [view, setView] = useState<ViewContent>('order');

  const setViewToOrder = useCallback(() => setView('order'), [setView]);

  const deviceHeight = window.innerHeight;

  const isSmallDevice = deviceHeight <= 568;

  const isRobot = isRobotDelivery(transportMethod);

  const orderTime = dayjs(orderCreatedAt);
  const now = dayjs();
  const diff = now.diff(orderTime, 'minutes');

  const drawerContent: DrawerContent = useMemo(
    () => ({
      unassigned: {
        headerTitle: 'Good news!',
        headerSubtitle: '',
        deliveryMethod: resolveDeliveryMethod(isRobot),
        gif: isRobot ? CocoBotSpinningGif : DeliveryBagGif,
        deliveryStatusMessage: ['Your order has been', `accepted by ${merchantName}`],
        progressBarStep: 'Step 1',
      },
      pastFiveminutes: {
        headerTitle: 'Hang tight...',
        headerSubtitle: `Order accepted at ${dayjs(orderCreatedAt).format('h:mm A')}`,
        deliveryMethod: resolveDeliveryMethod(isRobot),
        gif: isRobot ? CocoBotSpinningGif : DeliveryBagGif,
        deliveryStatusMessage: ['The kitchen is working on', 'your order!'],
        progressBarStep: 'Step 2',
      },
      goingToCustomer: {
        headerTitle: '',
        headerSubtitle: 'Estimated arrival time',
        deliveryMethod: resolveDeliveryMethod(isRobot),
        gif: isRobot ? CocoBotLoadedGif : DeliveryBagGif,
        deliveryStatusMessage: isRobot
          ? ['Your order has been', 'loaded and is on the way!']
          : ['Your order is on the way!'],
        progressBarStep: 'Step 3',
      },
      atCustomer: {
        headerTitle: isRobot ? 'It’s time to meet your Coco!' : 'Your order has arrived!',
        headerSubtitle: '',
        deliveryMethod: resolveDeliveryMethod(isRobot),
        gif: isRobot ? CocoBotArrivedGif : DeliveryBagArrivedGif,
        deliveryStatusMessage: isRobot
          ? ['To open your Coco, follow the', 'instructions you’ve received', 'via text message']
          : ['Your order has been dropped', 'off at your address'],
        progressBarStep: 'Step 5',
      },
      completed: {
        headerTitle: 'Delivered!',
        headerSubtitle: '',
        deliveryMethod: resolveDeliveryMethod(isRobot),
        gif: isRobot ? CocoBotArrivedGif : DeliveryBagArrivedGif,
        deliveryStatusMessage: [],
        progressBarStep: 'Step 5',
      },
      cancelled: {
        headerTitle: 'Cancelled',
        headerSubtitle: '',
        deliveryMethod: resolveDeliveryMethod(isRobot),
        gif: isRobot ? CocoBotArrivedGif : DeliveryBagArrivedGif,
        deliveryStatusMessage: [],
        progressBarStep: 'Step 5',
      },
    }),
    [isRobot, merchantName, orderCreatedAt]
  );

  let content = drawerContent.unassigned;

  if ((orderState === OrderState.UNASSIGNED && diff > 5) || orderState === OrderState.AT_MERCHANT) {
    content = drawerContent.pastFiveminutes;
  }

  if (orderState === OrderState.GOING_TO_CUSTOMER) {
    content = drawerContent.goingToCustomer;
    content.headerTitle = getEtaTitle(orderCreatedAt, givenEta);
  }
  if (orderState === OrderState.AT_CUSTOMER) {
    content = drawerContent.atCustomer;
  }
  if (orderState === OrderState.COMPLETED) {
    content = drawerContent.completed;
  }

  useEffect(() => {
    console.log('State', orderState);
    if (
      orderState === OrderState.UNASSIGNED ||
      orderState === OrderState.AT_MERCHANT ||
      orderState === OrderState.GOING_TO_MERCHANT
    ) {
      setOpen(true);
    } else if (orderState === OrderState.GOING_TO_CUSTOMER) {
      setOpen(false);
    } else if (orderState === OrderState.AT_CUSTOMER || orderState === OrderState.COMPLETED) {
      setOpen(true);
    }
  }, [orderState]);

  const isExpandEnabled = orderState === OrderState.GOING_TO_CUSTOMER && courierLocations;

  const handleExpand = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const extendedContainer = (
    <>
      <div
        className={`fixed bottom-0 bg-white w-full ${
          isSmallDevice ? 'top-14' : 'top-16'
        } flex flex-col space-y-4 xs:space-y-6 pb-4 pt-8 px-8 rounded-t-3xl overflow-y-hidden shadow-top-lg z-40`}
      >
        <div className="flex justify-center items-center">
          <img src={CocoLogo} alt="coco-header-logo" />
        </div>
        <div className="flex flex-col space-y-3 xs:space-y-4">
          <Header title={content.headerTitle} subtitle={content.headerSubtitle} />
          <div className="flex flex-col items-center">
            <div className={`${isSmallDevice ? 'w-32 h-32' : ''}`}>
              <img id="delivery-gif" src={content.gif} alt="coco-bot-gif" />
            </div>

            <span
              id="delivery-method"
              className="font-bold text-xs text-center text-coco-gray-light"
            >
              {content.deliveryMethod.map((m: string, i: number) => (
                <p key={i}>{m}</p>
              ))}
            </span>
          </div>

          <div className="flex justify-center mt-4">
            <ProgressBar stepToActivate={content.progressBarStep} />
          </div>
          <DeliveryStatusMessage message={content.deliveryStatusMessage} />
        </div>

        <div className="border-t border-b flex flex-col py-4">
          <div className="font-bold text-sm xs:text-base">Deliverys Address</div>
          <p
            id="delivery-customer-address"
            className=" text-xs xs:text-sm font-semibold text-coco-gray"
          >
            {customerAddress.address1}
          </p>
          <p
            id="delivery-customer-address"
            className=" text-xs xs:text-sm font-semibold text-coco-gray"
          >
            {customerAddress.address2}
          </p>
        </div>

        <div className="flex justify-center items-center z-50">
          <button onClick={() => setView('faq')} className="text-coco-black font-bold text-xs">
            Need help?
          </button>
        </div>
      </div>
    </>
  );

  const curledContainer = (
    <>
      <div className="fixed bottom-0 bg-white w-full flex flex-col space-y-6 pb-4 pt-8 px-8 rounded-t-3xl overflow-y-auto shadow-top-lg z-40"></div>
    </>
  );

  if (view === 'faq') {
    return <NeedHelp setViewToOrder={setViewToOrder} />;
  }
  return <>{open ? extendedContainer : curledContainer}</>;
};
