import { queryClient } from 'lib/react-query';
import { ReactNode, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { GridLoader } from 'react-spinners';

function ErrorFallback() {
  return (
    <div className=" w-screen h-screen flex flex-col justify-center items-center" role="alert">
      <h2 className="text-black font-bold text-2xl">Ooops, something went wrong :( </h2>
      <button
        className="mt-4 px-6 py-2 text-md shadow-lg bg-blue-600 text-white"
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresh
      </button>
    </div>
  );
}

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <Suspense
      fallback={
        <div className="w-screen h-screen flex justify-center items-center">
          <GridLoader color="#10b981" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools />}
          <Router>{children}</Router>
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  );
};
