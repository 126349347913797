export const Footer = () => {
  return (
    <div className="flex flex-col items-center">
      <p className="text-coco-black font-bold text-lg text-center mb-4">
        Want to learn more <br /> about Coco?
      </p>
      <div className="mb-7">
        <span className="text-coco-black font-bold text-base">Visit us at </span>{' '}
        <a
          href="https://www.cocodelivery.com/"
          target="_blank"
          rel="noreferrer"
          className="font-bold text-base text-transparent bg-clip-text bg-gradient-to-br from-red-500 to-pink-400 link"
        >
          cocodelivery.com
        </a>{' '}
      </div>
      <div className="font-semibold text-xs text-coco-gray-mid text-center space-y-1.5">
        <div>
          Still have more questions? <br />
        </div>
        <div>
          <a className="font-semibold text-coco-gray-mid text-center" href="sms:+1-312-313-1723">
            Text us at <span className="text-blue-600">(312) 313-1723</span>
          </a>
        </div>
      </div>
    </div>
  );
};
