import dayjs from 'dayjs';
import { DrawerContentKeys } from 'features/orders';
import { DeliveryStatusResponse } from 'typings/orders';

export const ordersQueryKeys = {
  orders: 'orders',
  order: 'order',
};

type MockDeliveryStatusResponse = {
  [k in DrawerContentKeys]: DeliveryStatusResponse;
};

export const mockPhone = '+17325476925';

export const mockAddress = {
  address1: 'Hollywood Hills',
};

export const mockResponse: MockDeliveryStatusResponse = {
  unassigned: {
    id: "testId",
    created_at: dayjs().subtract(5, 'minutes').format('ddd, MMM D, YYYY h:mm A'),
    destination: JSON.stringify(mockAddress),
    given_eta: 35,
    loaded_at: dayjs().format('ddd, MMM D, YYYY h:mm A'),
    merchant_name: 'In & Out',
    origin: JSON.stringify(mockAddress),
    state: 'unassigned',
    transport_method: 'robot',
    courier_locations: [],
    merchant_location: [-118.4814936509271, 34.0001564743375],
    customer_location: [-118.0034789, 34.0071444],
    driver_name: "Test driver",
    driver_phone: "+1234567891",
  },
  pastFiveminutes: {
    id: "testId",
    created_at: dayjs().subtract(5, 'minutes').format('ddd, MMM D, YYYY h:mm A'),
    destination: JSON.stringify(mockAddress),
    given_eta: 35,
    loaded_at: dayjs().format('ddd, MMM D, YYYY h:mm A'),
    merchant_name: 'In & Out',
    origin: JSON.stringify(mockAddress),
    state: 'at_merchant',
    transport_method: 'robot',
    courier_locations: [],    
    merchant_location: [-118.4814936509271, 34.0001564743375],
    customer_location: [-118.0034789, 34.0071444],
    driver_name: "Test driver",
    driver_phone: "+1234567891",  
  },
  goingToCustomer: {
    id: "testId",
    created_at: dayjs().subtract(5, 'minutes').format('ddd, MMM D, YYYY h:mm A'),
    destination: JSON.stringify(mockAddress),
    given_eta: 35,
    loaded_at: dayjs().format('ddd, MMM D, YYYY h:mm A'),
    merchant_name: 'In & Out',
    origin: JSON.stringify(mockAddress),
    state: 'going_to_customer',
    transport_method: 'robot',
    courier_locations: [],
    merchant_location: [-118.4814936509271, 34.0001564743375],
    customer_location: [-118.0034789, 34.0071444],
    driver_name: "Test driver",
    driver_phone: "+1234567891",  
  },
  atCustomer: {
    id: "testId",
    created_at: dayjs().subtract(5, 'minutes').format('ddd, MMM D, YYYY h:mm A'),
    destination: JSON.stringify(mockAddress),
    given_eta: 35,
    loaded_at: dayjs().format('ddd, MMM D, YYYY h:mm A'),
    merchant_name: 'In & Out',
    origin: JSON.stringify(mockAddress),
    state: 'completed',
    transport_method: 'robot',
    courier_locations: [],
    merchant_location: [-118.4814936509271, 34.0001564743375],
    customer_location: [-118.0034789, 34.0071444],
    driver_name: "Test driver",
    driver_phone: "+1234567891",  
  },
  completed: {
    id: "testId",
    created_at: dayjs().subtract(5, 'minutes').format('ddd, MMM D, YYYY h:mm A'),
    destination: JSON.stringify(mockAddress),
    given_eta: 35,
    loaded_at: dayjs().format('ddd, MMM D, YYYY h:mm A'),
    merchant_name: 'In & Out',
    origin: JSON.stringify(mockAddress),
    state: 'completed',
    transport_method: 'robot',
    courier_locations: [],
    merchant_location: [-118.4814936509271, 34.0001564743375],
    customer_location: [-118.0034789, 34.0071444],
    driver_name: "Test driver",
    driver_phone: "+1234567891",    
  },
  cancelled: {
    id: "testId",
    created_at: dayjs().subtract(5, 'minutes').format('ddd, MMM D, YYYY h:mm A'),
    destination: JSON.stringify(mockAddress),
    given_eta: 35,
    loaded_at: dayjs().format('ddd, MMM D, YYYY h:mm A'),
    merchant_name: 'In & Out',
    origin: JSON.stringify(mockAddress),
    state: 'completed',
    transport_method: 'robot',
    courier_locations: [],
    merchant_location: [-118.4814936509271, 34.0001564743375],
    customer_location: [-118.0034789, 34.0071444],
    driver_name: "Test driver",
    driver_phone: "+1234567891",   
  },    
};
