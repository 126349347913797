import { Disclosure } from '@headlessui/react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/outline';

const faqs = [
  {
    question: 'What is Coco?',
    answer:
      'Coco is a remotely-piloted delivery service that partners with restaurants and markets to make their local deliveries more affordable and sustainable. Using human pilots, Coco delivers to you quickly and reliably while reducing emissions and traffic in your neighborhood.',
  },
  {
    question: 'How can I receive a Coco robot delivery?',
    answer:
      'Coco partners with merchants directly and integrates with the delivery platforms they already use like DoorDash and UberEats. When you order through these platforms to an address within the robot-serviceable radius, you will receive a Coco robot delivery, pending device availability. If there is no robot available, we’ll dispatch a human courier to ensure your order gets to you as quickly as possible.',
  },
  {
    question: 'Why is Coco delivering my order?',
    answer:
      'We partner with local restaurants to assist with the deliveries within a certain radius. By using Coco, these restaurants are able to save on delivery costs and pass those savings on to you.',
  },

  {
    question: 'How does Coco work?',
    answer:
      'When you place an order with a merchant, a notification is sent to the merchant to load the Coco as soon as the food is ready to go! A Coco is standing by and begins it’s journey to you! Once it arrives, you receive instructions on how to open the bot.',
  },

  {
    question: 'Are Coco vehicles autonomous?',
    answer:
      'All of our vehicles are piloted by human operators. This allows us to make real time decisions to ensure the quality of your order as well as the safety of the community.',
  },

  {
    question: 'How will I know my Coco is outside?',
    answer:
      'Upon arrival, you will receive a text message with instructions on how to open your Coco. Our vehicles remain locked until you and only you confirm its arrival to ensure your food is secure and safe.',
  },

  {
    question: 'What if my building has multiple entrances?',
    answer:
      'You should expect to meet Coco where driving instructions typically route to. If you have trouble finding Coco, please contact us!',
  },

  {
    question: 'What if someone else opens my Coco?',
    answer:
      'Once Coco leaves the merchant, it remains locked for the entire duration of the trip. Upon arrival, you will receive a text notification that Coco has arrived, and you will be instructed to respond "UNLOCK" to receive your order. Only your phone can unlock Coco during the delivery.',
  },

  {
    question: 'What if I need to change my delivery address?',
    answer:
      'Please let our support team know ASAP via text so our team can arrange for your order to be delivered to the updated address.',
  },
];

export const Faq = () => {
  return (
    <div className="max-w-7xl w-full pb-6 overflow-y-scroll">
      <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <dl className="space-y-6 divide-y divide-gray-200">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6 faq">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-center text-gray-400">
                      <span className="text-xs font-semibold text-coco-black">{faq.question}</span>
                      <span className="ml-6 h-7 flex items-center">
                        {open ? (
                          <MinusCircleIcon className="h-6 w-6" aria-hidden="true" />
                        ) : (
                          <PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-xs text-coco-gray-light font-medium ">{faq.answer}</p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};
