import { StepName, STEP_STATUS } from '.';

type StepProps = {
  id: string;
  name: StepName;
  status: STEP_STATUS;
};

export const Step = ({ id, name, status }: StepProps) => {
  return (
    <div
      className={
        status === STEP_STATUS.CURRENT && name === 'Step 1'
          ? 'flex items-center justify-center bg-gradient-to-l w-5 h-5  from-red-400 via-pink-500 to-pink-600 rounded-full relative'
          : ''
      }
    >
      <div
        id={id}
        className={`group relative w-4.1 h-4.1 flex items-center justify-center ${
          status === STEP_STATUS.COMPLETE || (status === STEP_STATUS.CURRENT && name !== 'Step 1')
            ? 'bg-transparent'
            : 'bg-white   hover:border-none'
        } rounded-full `}
      >
        <span className="h-2.5 w-2.5 bg-transparent rounded-full" aria-hidden="true" />
        <span className="sr-only">{name}</span>
      </div>
    </div>
  );
};
