type DeliveryStatusMessageProps = {
  message: string[];
};

export const DeliveryStatusMessage = ({ message }: DeliveryStatusMessageProps) => {
  return (
    <div className="flex justify-center">
      <p
        id="delivery-status-message"
        className="text-coco-black font-bold text-sm xs:text-base text-center "
      >
        {message.map((m: string, i: number) => (
          <p key={i}>{m}</p>
        ))}
      </p>
    </div>
  );
};
