import CocoLogo from 'assets/svg/coco-header-logo.svg';
import { Header } from '../BottomDrawer/Header';
import { ProgressBar } from '../PogressBar';
import { DeliveryStatusMessage } from '../BottomDrawer/DeliveryStatusMessage';
import { Address, Coordinate, OrderState, TransportMethod } from 'typings/orders';
import { getDrawerContent } from './utils';
import { Faq } from 'features/orders';
import { useEffect, useRef, useState } from 'react';
import { Footer } from '../BottomDrawer/Footer';
import { XIcon } from '@heroicons/react/outline';

export type DeliveryDrawerProps = {
  orderCreatedAt: string;
  orderLoadedAt?: string;
  customerAddress: Address;
  orderState: OrderState;
  merchantName: string;
  transportMethod: TransportMethod;
  givenEta: number;
  courierLocations: Coordinate[];
  driverName: string;
  driverPhone: string;
};

export const DeliveryDrawer = (props: DeliveryDrawerProps) => {
  const {
    orderCreatedAt,
    orderLoadedAt,
    orderState,
    merchantName,
    transportMethod,
    givenEta,
    customerAddress,
    driverName,
    driverPhone,
  } = props;
  const content = getDrawerContent(
    transportMethod,
    orderCreatedAt,
    orderLoadedAt,
    givenEta,
    merchantName,
    orderState,
    driverName,
    driverPhone
  );
  const [isHelpExpanded, setIsHelpExpanded] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const DeliveryStatusView = (
    <div className="mt-8 mb-3 mx-8 xs:space-y-6 space-y-4 overflow-y-auto">
      <div className="flex justify-center items-center">
        <img src={CocoLogo} alt="coco-header-logo" />
      </div>
      <div className="flex flex-col space-y-3 xs:space-y-4">
        <Header title={content.headerTitle} subtitle={content.headerSubtitle} />
        <div className="flex flex-col items-center">
          <img
            className={`${window.innerHeight < 650 ? 'w-60' : 'w-64'}`}
            id="delivery-gif"
            src={content.gif}
            alt="coco-bot-gif"
          />
          <span id="delivery-method" className="font-bold text-xs text-center text-coco-gray-light">
            {content.deliveryMethod.map((line: string, key: number) => (
              <p key={key}>{line}</p>
            ))}
          </span>
        </div>
        <div className="flex justify-center mt-4">
          <ProgressBar stepToActivate={content.progressBarStep} />
        </div>
        <DeliveryStatusMessage message={content.deliveryStatusMessage} />
      </div>
      <div className="border-t border-b flex flex-col py-4">
        <div className="font-bold text-sm xs:text-base">Delivery Address</div>
        <p
          id="delivery-customer-address"
          className=" text-xs xs:text-sm font-semibold text-coco-gray"
        >
          {customerAddress.address1}
        </p>
        <p
          id="delivery-customer-address"
          className=" text-xs xs:text-sm font-semibold text-coco-gray"
        >
          {customerAddress.address2}
        </p>
      </div>

      <div className="justify-center items-center z-50 font-semibold text-xs text-coco-gray-mid text-center space-y-1.5">
        {/* <div className="flex flex-col space-y-2 justify-center items-center"> */}
        {transportMethod === TransportMethod.COURIER && driverPhone && (
          <div>
            <a className="font-semibold text-coco-gray-mid text-center" href={`tel:${driverPhone}`}>
              <span className="text-blue-600 font-bold text-xs">Call your driver</span>
            </a>
          </div>
        )}
        <div className="flex justify-center items-center z-50">
          <button
            onClick={() => setIsHelpExpanded(true)}
            className="text-coco-black font-bold text-xs"
          >
            Need help?
          </button>
        </div>
        {/* </div> */}
      </div>
    </div>
  );

  const HelpView = (
    <div className="w-full flex flex-col space-y-1 xs:space-y-6 px-9 py-9 overflow-y-auto z-40 pb-48">
      <div className="relative flex justify-center items-center">
        <div className="absolute -left-2">
          <XIcon onClick={() => setIsHelpExpanded(false)} className="h-6 w-6 p-0 m-0" />
        </div>
        <h1 className="text-coco-dark text-center font-bold text-base pl-2">Need Help?</h1>
      </div>
      <Faq />
      <Footer />
    </div>
  );

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [isHelpExpanded]);

  return (
    <div ref={scrollRef} className="overflow-y-scroll w-screen h-screen">
      {isHelpExpanded ? HelpView : DeliveryStatusView}
    </div>
  );
};
